import Tablesort from 'tablesort';
import snarkdown from 'snarkdown';

import UserFeedback from 'components/user-feedback';
import Report from 'components/report';

function init() {
  activateAccountDetailsTrigger();
  initializeDismissableAlerts();
  UserFeedback.initialize();

  enablePdfDownload();
  enableMarkdown();
  enableTableSorting();
}

var forEach = function(arr, cb, scope) {
  for (var i = 0; i < arr.length; i++) {
    cb.call(scope, arr[i]);
  }
};

function initializeDismissableAlerts() {
  const notices = document.querySelectorAll('.js-notice');
  if (!notices) { return; }

  forEach(notices, (notice) => {
    const STORAGE_KEY = notice.getAttribute('data-id');
    const alreadyAccepted = localStorage.getItem(STORAGE_KEY);
    const closeButton = notice.querySelector('.js-notice-close');

    if (alreadyAccepted) { return; }

    notice.classList.add('is-visible');
    closeButton.addEventListener('click', (event) => {
      event.preventDefault();
      notice.classList.remove('is-visible');
      notice.parentNode.removeChild(notice);
      localStorage.setItem(STORAGE_KEY, true);
    });
  });
}

function activateAccountDetailsTrigger() {
  const trigger = document.getElementById('account-details-trigger');
  const target = document.getElementById('account-details-pane');

  if (!trigger) { return; }

  trigger.addEventListener('click', (event) => {
    target.classList.toggle('is-open');
  });
}

function enableMarkdown() {
  const elements = document.querySelectorAll('.js-markdown');
  if (!elements) { return; }
  forEach(elements, (element) => {
    const html = snarkdown(element.textContent);
    element.innerHTML = html;
  });
};

function enableTableSorting() {
  const tables = document.querySelectorAll('.js-table-sort');
  if (!tables) { return; }
  forEach(tables, (table) => {
    const sort = new Tablesort(table, {
      descending: true
    });
  });
}

function enablePdfDownload() {
  const dataElement = document.getElementById('report');
  if (!dataElement) { return; }

  const data = JSON.parse(dataElement.textContent);
  const report = new Report(data);
  const btn = document.querySelector('.js-download');

  report.generatePdf();
  btn.addEventListener('click', (event) => {
    report.download();
  });
}

init();
