import Cookies from 'lib/cookies';

class UserFeedback {
  static initialize() {
    const CSRF_COOKIE_NAME = 'csrftoken';

    const form = document.getElementById('user-feedback-rating');
    const triggers = [...form.querySelectorAll('.js-rating-item')];
    const showSuccessMessage = () => {
      const message = document.createElement('div');
      message.className = 'form__success-message';
      message.textContent = 'Grazie per il tuo feedback.';
      form.appendChild(message);
    }
    const sendRating = (rating) => {
      const customer = form.getAttribute('data-customer');
      const data = {
        customer: customer,
        rating: rating
      };
      const request = new XMLHttpRequest();
      request.open('POST', '/feedback/', true);
      request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
      request.setRequestHeader('X-CSRFToken', Cookies.get(CSRF_COOKIE_NAME));
      request.onload = function() {
        if (this.status >= 200 && this.status < 400) {
          showSuccessMessage();
        } else {
          showSuccessMessage();
        }
      };
      request.onerror = function() {
        showSuccessMessage();
      };
      request.send(JSON.stringify(data));
    }

    if (!triggers) { return; }

    triggers.forEach((trigger) => {
      trigger.addEventListener('click', (event) => {
        const rating = trigger.getAttribute('data-rating');
        trigger.classList.add('is-selected');
        form.style.pointerEvents = 'none';
        sendRating(rating + '/5');
      });
    });
  }
}

export default UserFeedback;
