class Cookies {

  static get(name) {
    var val = null;

    if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';');
      for (var i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        // Does this cookie string begin with the name we want?
        if (cookie.substring(0, name.length + 1) === (name + '=')) {
          val = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }

    return val;
  }

}

export default Cookies;
